import React from "react";
import { Modal } from "react-bootstrap";
import "./index.css";

export default function ModalCafofo({ children, ...props }) {
  return (
    <Modal
      show={props.open}
      onHide={props.close}
      size={props.size || "lg"}
      centered
      style={{
        paddingRight: "17px",
        display: "block",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>{props.titulo}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"conteudo"}>
          {props.content}
          {children}
        </div>
      </Modal.Body>
    </Modal>
  );
}
