import React, { useEffect, useState } from "react";
import "../Dashboard/index.css";
import { Link } from "react-router-dom";
import { errorToast } from "../../components/Toast";
import CookieConsent from "react-cookie-consent";

import SimpleImageSlider from "react-simple-image-slider";
import api from "../../services/api";

import { AiOutlineSearch } from "react-icons/ai";

import { Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";

export default function Dashboard({ history }) {
  const [search, setSearch] = useState([]);
  const [resultSearch, setResultSearch] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [citySearch, setCitySearch] = useState("");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const itensPaginacao = [];

  async function fetchData(pageNumber, shouldRefresh, city) {
    const user_id = localStorage.getItem("user");
    searchList(user_id, pageNumber, shouldRefresh, city);
    setUser(user_id);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function searchList(
    user,
    pageNumber = page,
    shouldRefresh = false,
    city = ""
  ) {
    if (total && pageNumber > total) {
      setHasMore(false);
      return;
    }
    setHasMore(true);
    setLoading(true);
    const res = await api.get(
      `/vacancies?city=${city}&limit=8&page=${pageNumber}`,
      {
        headers: { Authorization: user },
      }
    );
    setTotal(Math.ceil(res.data.count / 8));
    setSearch(res.data.vacancies);
    setLoading(false);
  }

  function ImageSliderData(thumb) {
    let arr = [];
    JSON.parse(thumb).forEach((item) => {
      arr.push({ url: item.link });
    });

    return arr;
  }

  async function openDetail(item) {
    window.open(`/vacancy/${item.id}`);
  }

  let ultimaPaginaFoiOculta = false;
  for (let numeroPagina = 1; numeroPagina <= total; numeroPagina++) {
    const paginaItem = (
      <Pagination.Item
        key={numeroPagina}
        active={numeroPagina === page}
        onClick={() => {
          setPage(numeroPagina);
          fetchData(numeroPagina);
        }}
      >
        {numeroPagina}
      </Pagination.Item>
    );

    let numeroPaginaOculta;

    if (
      total <= 15 ||
      (page - 2 <= numeroPagina && page + 2 >= numeroPagina) ||
      page === numeroPagina ||
      numeroPagina <= 3 ||
      numeroPagina > total - 3
    ) {
      itensPaginacao.push(paginaItem);
      ultimaPaginaFoiOculta = false;
    } else {
      if (!ultimaPaginaFoiOculta) {
        if (page < numeroPagina) {
          numeroPaginaOculta = page + 3 + 2;
        } else {
          numeroPaginaOculta = page - 3 - 2;
        }

        if (numeroPaginaOculta > total || numeroPaginaOculta < 1) {
          numeroPaginaOculta = page;
        }

        let paginaOculta = (
          <Pagination.Item
            key={numeroPagina}
            active={false}
            onClick={() => {
              setPage(numeroPaginaOculta);
              fetchData(numeroPaginaOculta);
            }}
          >
            ...
          </Pagination.Item>
        );
        itensPaginacao.push(paginaOculta);
        ultimaPaginaFoiOculta = true;
      }
    }
  }

  return (
    <div className="wrapperPadding">
      <div className="search-input-wrapper">
        <input
          className="search-input"
          type="text"
          onChange={(e) => {
            setCitySearch(e.target.value);
          }}
          placeholder="Onde deseja morar?"
        />
        <div className="search-input-button">
          <AiOutlineSearch
            size={24}
            onClick={() => {
              fetchData(1, true, citySearch);
            }}
          />
        </div>
      </div>
      {loading ? (
        <Loading color="primary" />
      ) : (
        <ul className="vacancy-list">
          {search.map((vacancy) => {
            return (
              <li
                key={vacancy.id}
                onClick={() => {
                  openDetail(vacancy);
                }}
              >
                {vacancy.thumbnail_url === null ||
                vacancy.thumbnail_url === "" ||
                vacancy.thumbnail_url === undefined ||
                vacancy.thumbnail_url === "[]" ||
                vacancy.thumbnail_url.length <= 0 ? (
                  <img
                    className="imgBox"
                    src={"https://i.imgur.com/iuSUlvZ.png"}
                  />
                ) : (
                  <SimpleImageSlider
                    width={300}
                    height={200}
                    images={ImageSliderData(vacancy.thumbnail_url)}
                    showBullets={false}
                    showNavs={true}
                    navSize={0}
                    navMargin={10}
                    style={{ borderRadius: 11 }}
                  />
                )}
                <div className="nameGenderWrapper">
                  <strong className="vacancyName">{vacancy.name}</strong>
                  {vacancy.gender === "Masculina" ? (
                    <FontAwesomeIcon icon={faMars} color="#87CEEB" />
                  ) : vacancy.gender === "Feminina" ? (
                    <FontAwesomeIcon icon={faVenus} color="#FF99CC" />
                  ) : vacancy.gender === "Unisex" ? (
                    <FontAwesomeIcon icon={faVenusMars} color="#999900" />
                  ) : (
                    <></>
                  )}
                </div>
                <span>
                  {vacancy.price
                    ? `R$ ${vacancy.price} /mês`
                    : "PREÇO A NEGOCIAR"}
                </span>
                <span>
                  {vacancy.city} - {vacancy.state}
                </span>
              </li>
            );
          })}
        </ul>
      )}

      <Link to={!user ? "/login" : "/new"} className="newVacancyButton">
        <button className="btn btn-secondary" type="button">
          {!user ? "Fazer Login" : "Cadastrar nova vaga"}
        </button>
      </Link>
      <Link to="/dashboard" className="newVacancyButtonFake">
        <button className="btnc" type="button"></button>
      </Link>
      <div className="d-sm-flex justify-content-between">
        <Pagination>
          <Pagination.First
            onClick={() => {
              setPage(1);
              fetchData(1);
            }}
          />
          <Pagination.Prev
            onClick={() => {
              if (page !== 1) {
                setPage(page - 1);
                fetchData(page - 1);
              }
            }}
          />
          {itensPaginacao}
          <Pagination.Next
            onClick={() => {
              if (page !== total) {
                setPage(page + 1);
                fetchData(page + 1);
              }
            }}
          />
          <Pagination.Last
            onClick={() => {
              setPage(total);
              fetchData(total);
            }}
          />
        </Pagination>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="cafofoCookie"
        style={{ background: "#444" }}
        buttonStyle={{
          color: "#444",
          fontSize: "13px",
          backgroundColor: "#fff",
        }}
        expires={150}
      >
        Este site utiliza cookies para otimizar a sua experiencia.
      </CookieConsent>
    </div>
  );
}
