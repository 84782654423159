import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { Spinner } from "react-bootstrap";

import { AiOutlineArrowLeft } from "react-icons/ai";

import { errorToast, successToast } from "../../components/Toast";

import api from "../../services/api";

export default function ForgotPass() {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password2, setPassword2] = useState("");
  const [password, setPassword] = useState("");
  const [loadingForgotEmail, setLoadingForgotEmail] = useState(false);
  const [loadingForgotToken, setLoadingForgotToken] = useState(false);
  const [loadingForgotPass, setLoadingForgotPass] = useState(false);
  const [level, setLevel] = useState("E");

  async function handleSubmit(e) {
    e.preventDefault();
    setLoadingForgotEmail(true);
    try {
      const response = await api.post("/users/recovery", {
        email,
      });
      const menssagen = response.data;
      if (menssagen) {
        setLoadingForgotEmail(false);
        setLevel("T");
        successToast.fire({
          title: `${menssagen}`,
        });
      }
    } catch {
      errorToast.fire({
        title: `Erro, tente novamente!`,
      });
      setLoadingForgotEmail(false);
    }
  }

  async function handleSubmitToken(e) {
    e.preventDefault();
    setLoadingForgotToken(true);
    if (token === "") {
      errorToast.fire({
        title: `O campo token não pode estar vazio ou é inválido`,
      });
    } else {
      setLoadingForgotToken(false);
      setLevel("P");
    }
  }

  async function handleSubmitPass(e) {
    e.preventDefault();
    setLoadingForgotPass(true);

    if (password === "" || password.length < 8) {
      setLoadingForgotPass(false);
      errorToast.fire({
        title: `Senha precisa ter pelo menos 8 digitos`,
      });
    } else {
      if (password2 === "" || password2.length < 8) {
        setLoadingForgotPass(false);
        errorToast.fire({
          title: `Senha precisa ter pelo menos 8 digitos`,
        });
      } else {
        if (password !== password2) {
          setLoadingForgotPass(false);
          errorToast.fire({
            title: `Senha precisa ter pelo menos 8 digitos`,
          });
        } else {
          try {
            const response = await api.post(`/users/change-password/${token}`, {
              password,
            });
            const res = response.data;
            if (res) {
              successToast.fire({
                title: `Senha ${res}`,
              });
              setLoadingForgotPass(false);
              window.location.href = "/login";
            } else {
              setLoadingForgotPass(false);
            }
          } catch {
            setLoadingForgotPass(false);
          }
        }
      }
    }
  }

  return (
    <>
      {level === "T" ? (
        <div
          onClick={() => {
            setLevel("E");
          }}
          className="back-button-forgot"
        >
          <AiOutlineArrowLeft size={20} />
          Voltar
        </div>
      ) : level === "P" ? (
        <div
          onClick={() => {
            setLevel("T");
          }}
          className="back-button-forgot"
        >
          <AiOutlineArrowLeft size={20} />
          Voltar
        </div>
      ) : (
        <></>
      )}
      {level === "E" ? (
        <form className="form-wrapper-forgot" onSubmit={handleSubmit}>
          <label htmlFor="email">Qual o seu EMAIL?</label>
          <input
            className="input-forgot"
            type="email"
            id="email"
            required
            placeholder="Digite seu e-mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <button className="btn btn-secondary" type="submit">
            {loadingForgotEmail ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "CONTINUAR"
            )}
          </button>
        </form>
      ) : level === "T" ? (
        <form className="form-wrapper-forgot" onSubmit={handleSubmitToken}>
          <label htmlFor="token">Qual o token recebido?</label>
          <input
            className="input-forgot"
            type="token"
            id="token"
            required
            placeholder="Digite TOKEN recebido por e-mail"
            value={token}
            onChange={(event) => setToken(event.target.value)}
          />
          <button className="btn btn-secondary" type="submit">
            {loadingForgotToken ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "CONTINUAR"
            )}
          </button>
        </form>
      ) : level === "P" ? (
        <form className="form-wrapper-forgot">
          <label htmlFor="password">Digite a sua senha</label>
          <input
            className="input-forgot"
            type="password"
            id="password"
            required
            placeholder="Escolha sua senha com sabedoria"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <label htmlFor="password2">Digite novamente a sua senha</label>
          <input
            className="input-forgot"
            type="password"
            id="password2"
            required
            placeholder="Só pra garantir"
            value={password2}
            onChange={(event) => setPassword2(event.target.value)}
          />
          <button
            onClick={(e) => {
              handleSubmitPass(e);
            }}
            className="btn btn-secondary"
          >
            {loadingForgotPass ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "TROCAR"
            )}
          </button>
        </form>
      ) : (
        <></>
      )}
    </>
  );
}
