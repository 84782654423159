import React from "react";
import { Link } from "react-router-dom";

import "./index.css";

import { FaEthernet, FaCouch } from "react-icons/fa";
import { BiJoystick } from "react-icons/bi";
import { BsTv, BsThermometerHigh } from "react-icons/bs";
import { AiOutlineWifi, AiOutlinePrinter, AiFillPhone } from "react-icons/ai";
import {
  MdOutlineMicrowave,
  MdTerrain,
  MdLocalLaundryService,
  MdOutdoorGrill,
  MdOutlinePool,
  MdOutlineNightlife,
  MdOutlinePets,
  MdOutlineLogin,
} from "react-icons/md";
import { GoPaintcan } from "react-icons/go";
import {
  GiBroom,
  GiRingingAlarm,
  GiWashingMachine,
  GiSteam,
  GiHomeGarage,
  GiSlicedBread,
  GiLightBulb,
  GiWaterDrop,
} from "react-icons/gi";
import { IoIosVideocam } from "react-icons/io";
import { IoWomanSharp } from "react-icons/io5";
import { SiNetflix } from "react-icons/si";
import { GrMapLocation } from "react-icons/gr";

export default function OfferMidias({ benefits }) {
  return (
    <>
      {benefits ? (
        JSON.parse(benefits).map((item) => {
          return item === "Internet" ? (
            <div className="boxBenefits" key={item}>
              <FaEthernet size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Video-Game" ? (
            <div className="boxBenefits" key={item}>
              <BiJoystick size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "TV" ? (
            <div className="boxBenefits" key={item}>
              <BsTv size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "TV a Cabo" ? (
            <div className="boxBenefits" key={item}>
              <AiOutlineWifi size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Mobiliada" ? (
            <div className="boxBenefits" key={item}>
              <FaCouch size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Microondas" ? (
            <div className="boxBenefits" key={item}>
              <MdOutlineMicrowave size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Área externa" ? (
            <div className="boxBenefits" key={item}>
              <MdTerrain size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Sem Trote" ? (
            <div className="boxBenefits" key={item}>
              <GoPaintcan size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Lavanderia" ? (
            <div className="boxBenefits" key={item}>
              <MdLocalLaundryService size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Empregada" ? (
            <div className="boxBenefits" key={item}>
              <GiBroom size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Comadre" ? (
            <div className="boxBenefits" key={item}>
              <IoWomanSharp size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Alarme" ? (
            <div className="boxBenefits" key={item}>
              <GiRingingAlarm size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Impressora" ? (
            <div className="boxBenefits" key={item}>
              <AiOutlinePrinter size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Churrasqueira" ? (
            <div className="boxBenefits" key={item}>
              <MdOutdoorGrill size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Maquina de lavar" ? (
            <div className="boxBenefits" key={item}>
              <GiWashingMachine size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Air Fryer" ? (
            <div className="boxBenefits" key={item}>
              <GiSteam size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Garagem" ? (
            <div className="boxBenefits" key={item}>
              <GiHomeGarage size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Camera de segurança" ? (
            <div className="boxBenefits" key={item}>
              <IoIosVideocam size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Compras Basicas" ? (
            <div className="boxBenefits" key={item}>
              <GiSlicedBread size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Luz" ? (
            <div className="boxBenefits" key={item}>
              <GiLightBulb size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Água" ? (
            <div className="boxBenefits" key={item}>
              <GiWaterDrop size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Wi-Fi" ? (
            <div className="boxBenefits" key={item}>
              <AiOutlineWifi size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Piscina" ? (
            <div className="boxBenefits" key={item}>
              <MdOutlinePool size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "SmartTV" ? (
            <div className="boxBenefits" key={item}>
              <SiNetflix size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Proximo à Faculdade" ? (
            <div className="boxBenefits" key={item}>
              <GrMapLocation size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Telefone Fixo" ? (
            <div className="boxBenefits" key={item}>
              <AiFillPhone size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Sauna" ? (
            <div className="boxBenefits" key={item}>
              <BsThermometerHigh size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Boate" ? (
            <div className="boxBenefits" key={item}>
              <MdOutlineNightlife size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Aceita pet" ? (
            <div className="boxBenefits" key={item}>
              <MdOutlinePets size={24} color="#444" />
              <div className="textBenefits">{item}</div>
            </div>
          ) : item === "Faça login para visualizar" ? (
            <Link to={"/login"} className="boxBenefits" key={item}>
              <MdOutlineLogin size={24} color="#444" />
              <div
                style={{ textDecoration: "underline", cursor: "pointer" }}
                className="textBenefits"
              >
                {item}
              </div>
            </Link>
          ) : (
            <></>
          );
        })
      ) : (
        <div>Nada à oferecer</div>
      )}
    </>
  );
}
