import React from "react";
import "./index.css";
import {
  EmailShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import { IoMdCopy } from "react-icons/io";

import { successToast } from "../../../components/Toast";

export default function Share({ item }) {
  function shareVacancy() {
    navigator.clipboard.writeText(
      `Olha a vaga que encontrei no Meu Cafofo, combina com você: www.meucafofo.com/vacancy/${item.id}`
    );
    successToast.fire({
      title: `Mande o link copiado para os seus amigos`,
    });
  }
  return (
    <div className="share-modal-wrapper">
      <div className="share-tittle">
        Compatilhe esse lugar com os seus amigos e colegas que estão em busca de
        um cafofo
      </div>
      <div className="vacancy-infos">
        <div
          className="vacancy-img"
          style={{
            backgroundImage: `url(${
              item.thumbnail_url === null ||
              item.thumbnail_url === "" ||
              item.thumbnail_url === undefined ||
              item.thumbnail_url === "[]" ||
              item.thumbnail_url.length <= 0
                ? "https://i.imgur.com/iuSUlvZ.png"
                : JSON.parse(item.thumbnail_url)[0].link
            })`,
          }}
        />
        <p className="vacancy-name">{item.name}</p>
      </div>
      <ul className="share-buttons-wrapper">
        {/* <div className="share-button">
          <FacebookMessengerShareButton quote={title}>
            <FacebookMessengerIcon size={size} />
          </FacebookMessengerShareButton>
        </div> */}
        <li className="share-button">
          <WhatsappShareButton
            title={`Olha a vaga que encontrei no Meu Cafofo, combina com você: ${item.name}`}
            separator=" "
            url={`https://meucafofo.com/vacancy/${item.id}`}
            windowWidth={800}
          >
            <WhatsappIcon size={40} />
            WhatsApp
          </WhatsappShareButton>
        </li>
        <li className="share-button">
          <EmailShareButton
            subject={`Confira essa vaga: ${item.name}`}
            body={`Olha a vaga que encontrei no Meu Cafofo, combina com você: www.meucafofo.com/vacancy/${item.id}`}
          >
            <EmailIcon size={40} />
            Email
          </EmailShareButton>
        </li>
        <li className="share-button">
          <TelegramShareButton
            title={`Olha a vaga que encontrei no Meu Cafofo, combina com você: ${item.name}`}
            url={`www.meucafofo.com/vacancy/${item.id}`}
            windowWidth={800}
          >
            <TelegramIcon size={40} />
            Telegram
          </TelegramShareButton>
        </li>
        <li className="share-button">
          <button
            onClick={() => {
              shareVacancy();
            }}
            className="share-button-copy"
          >
            <IoMdCopy size={40} color="#444" />
            Copiar link
          </button>
        </li>
      </ul>
    </div>
  );
}
