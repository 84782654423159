import React, { useState, useEffect } from "react";

import ChatService from "../../services/ChatService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { errorToast } from "../../components/Toast";

export default function Chat({ history, item }) {
  const [data, setData] = useState([]);
  const [token, setToken] = useState("");

  useEffect(() => {
    async function buscaToken() {
      setToken(localStorage.getItem("user"));
    }
    buscaToken();
  }, []);

  function sendChat() {
    if (token) {
      ChatService.criaChat(item.id, token).then((res) => {
        setData(res.data);
        const user_id = localStorage.getItem("id");
        if (user_id) {
          history.push("/chats", { data: res.data, item });
        }
      });
    } else {
      errorToast.fire({
        title: "Faça login para usar o chat",
      });
      history.push("/dashboard");
    }
  }
  return (
    <div style={{ display: "flex", width: "50%" }}>
      <button
        style={!token ? { opacity: 0.6 } : {}}
        disabled={!token}
        onClick={sendChat}
        className="buttonInitChat"
      >
        <FontAwesomeIcon
          icon={faComment}
          color="#fff"
          style={{ marginRight: 10 }}
        />
        <span className="buttonText">{!token ? "Faça login" : "Chat"}</span>
      </button>
    </div>
  );
}
