import React from "react";
import api from "../services/api";

const chatService = {
  criaChat: async (id, token) => {
    return await api.post(
      `/chats/${id}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
  },
  buscaChatsReceived: () => {
    return api.get(`/chats`, {
      headers: {
        Authorization: localStorage.getItem("user"),
      },
    });
  },
  buscaChats: async () => {
    return await api.get(`/chats/openned`, {
      headers: {
        Authorization: localStorage.getItem("user"),
      },
    });
  },
  entraChatSelecionado: async (idChat) => {
    return await api.get(`/chats/${idChat}`, {
      headers: {
        Authorization: localStorage.getItem("user"),
      },
    });
  },
  storeMenssage: async (idChat, message) => {
    return await api.post(
      `/chats/${idChat}/message`,
      { message: message },
      {
        headers: {
          Authorization: localStorage.getItem("user"),
        },
      }
    );
  },
  openVacancySelected: async (idVacancy) => {
    return await api.get(`/vacancies/${idVacancy}`, {
      headers: {
        Authorization: localStorage.getItem("user"),
      },
    });
  },
  excluirChat: async (idChat) => {
    return await api.delete(`/chats/${idChat}`, {
      headers: {
        Authorization: localStorage.getItem("user"),
      },
    });
  },
};

export default chatService;
