import React, { useState } from "react";
import api from "../../services/api";
import "../Register/index.css";
import { Link } from "react-router-dom";

import { errorToast, successToast } from "../../components/Toast";

import { Spinner } from "react-bootstrap";

import backgoundImg from "../../assets/background_img_1920.png";
import backgoundImg_1360 from "../../assets/background_img_1360.png";
import google_play from "../../assets/google_play.png";
import logo from "../../assets/logo.svg";

export default function Register({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    try {
      setLoading(true);
      event.preventDefault();
      const response = await api.post("/sessions", {
        name,
        email,
        password,
        action: "register",
        type: 1,
      });
      const { id, status, token } = response.data;
      if (status == 1) {
        localStorage.setItem("user", token);
        localStorage.setItem("id", id.toString());
        localStorage.setItem("name", name);
        setEmail("");
        setPassword("");
        setName("");
        setLoading(false);
        successToast.fire({
          title: `Cadastro salvo com sucesso!`,
        });
        history.push("/dashboard");
      } else {
        errorToast.fire({
          title: `Ocorreu um erro ao salvar dados tente novamente.`,
        });
      }
    } catch {
      errorToast.fire({
        title: `Erro, tente novamente.`,
      });
      setLoading(false);
      history.push("/register");
    }
  }
  return (
    <div className="loginScreenWrapper">
      <div className="loginWrapper">
        <div className="logoWrapper">
          <img src={logo} alt="Meu Cafofo" style={{ marginBottom: 50 }} />
          <p>
            <strong>Faça seu cadastro</strong> e comece já a usar!
          </p>
        </div>

        <form className="formWrapper" onSubmit={handleSubmit}>
          <label htmlFor="name">NOME</label>
          <input
            type="text"
            id="name"
            className="input"
            placeholder="Digite seu nome"
            value={name}
            required
            onChange={(event) => setName(event.target.value)}
          />

          <label htmlFor="email">E-MAIL</label>
          <input
            type="email"
            id="email"
            className="input"
            placeholder="Digite seu e-mail"
            value={email}
            required
            onChange={(event) => setEmail(event.target.value)}
          />

          <label htmlFor="password">SENHA</label>
          <input
            type="password"
            id="password"
            className="input"
            value={password}
            required
            placeholder="Digite sua senha"
            onChange={(event) => setPassword(event.target.value)}
          />

          <div className="buttonsRegister">
            <button className="btn btn-secondary btn-full" type="submit">
              {loading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Cadastrar"
              )}
            </button>
            <Link to="/login">
              <button className="btn btn-full btn-login" type="button">
                Já tenho uma conta, fazer login
              </button>
            </Link>
          </div>
        </form>
      </div>
      <div
        className="backgoundImg"
        style={{ backgroundImage: `url(${backgoundImg})` }}
      />
      <div
        className="backgoundImg1360"
        style={{ backgroundImage: `url(${backgoundImg_1360})` }}
      />
      <div
        className="downloadGoogle"
        style={{ backgroundImage: `url(${google_play})` }}
        onClick={(e) => {
          e.preventDefault();
          window.open(
            "https://play.google.com/store/apps/details?id=br.com.egend.meucafofo"
          );
        }}
      />
    </div>
  );
}
