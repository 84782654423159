import React, { useState, useEffect } from "react";
import "./index.css";
import { errorToast, successToast } from "../../components/Toast";

import usersService from "../../services/UsersService";
import ibge from "../../services/ibge";
import imgur from "../../services/imgur";

import Loading from "../../components/Loading";

import { Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export default function EditProfile({ history, setEdit, setNameTo }) {
  const [nameToWelcome, setNameToWelcome] = useState("");
  const [userId, setUserId] = useState(0);
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [cityList, setCityList] = useState([]);

  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [phone, setPhone] = useState(null);
  const [type, setType] = useState(null);
  const [listState, setListState] = useState([
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
  ]);
  const configs = {
    selectSomeItems: "Selecione",
    allItemsAreSelected: "Todos itens selecionados",
    selectAll: "Selecionar todos",
    search: "Pesquisar",
  };

  useEffect(() => {
    setLoading(true);
    function load() {
      setNameToWelcome(localStorage.getItem("name"));
      setUserId(localStorage.getItem("id"));
    }
    try {
      usersService.getUsersData().then((res) => {
        setLoading(false);
        setItem(res.data);
        setNameTo(res.data.name);
        setName(res.data.name);
        localStorage.setItem("name", res.data.name);
        setEmail(res.data.email);
        setAvatar(res.data.avatar);
        setAddress(res.data.address);
        setCity(res.data.city);
        setState(res.data.state);
        setPhone(res.data.phone);
        setType(Number(res.data.type));
      });
    } catch {
      setLoading(false);
      errorToast.fire({
        title: "Erro ao buscar os dados, tente novamente",
      });
    }
    load();
  }, []);

  useEffect(() => {
    ibge.buscarMunicipiosPorUF(state).then((response) => {
      setLoadingCity(true);
      let listaCidades = [];
      response.data.map((city, index) =>
        listaCidades.push({ label: city.nome, id: city.id, value: city.nome })
      );
      setCityList(listaCidades);
      setLoadingCity(false);
    });
  }, [state]);

  function montaValue(lista, selecionado) {
    const index = lista.findIndex((item) => item.value == selecionado);
    return lista[index];
  }

  async function selecionaThumb(fileItem) {
    setLoadingImage(true);
    try {
      let base64String = "";
      var file = fileItem[0];
      var reader = new FileReader();
      reader.onload = function () {
        base64String = reader.result.replace("data:", "").replace(/^.+,/, "");

        imgur.uploadApiCafofo(base64String).then((response) => {
          setAvatar(response.data.data.link);
          localStorage.setItem("profileImage", response.data.data.link);
          setLoadingImage(false);
        });
      };
      reader.readAsDataURL(file);
    } catch {
      setLoadingImage(false);
      errorToast.fire({
        title: "Erro ao enviar a foto, tente novamente",
      });
    }
  }

  async function saveProfile() {
    setSaveLoading(true);
    let dados = {
      name: name,
      email: email,
      type: Number(type),
      avatar: avatar,
      address: address,
      state: state,
      city: city,
    };
    try {
      usersService.putUsersData(dados).then(async (res) => {
        setSaveLoading(false);
        localStorage.setItem("name", name);
        localStorage.setItem("profileImage", avatar);
        successToast.fire({
          title: "Perfil salvo!!!",
        });
      });
    } catch {
      setSaveLoading(false);
      errorToast.fire({
        title: "Erro ao salvar, tente novamente",
      });
    }
  }

  return (
    <>
      <div
        onClick={() => {
          setEdit(false);
        }}
        className="back-icon"
      >
        <FontAwesomeIcon icon={faChevronLeft} color="#444" />
        Voltar
      </div>

      {loading ? (
        <Loading color="primary" />
      ) : (
        <Form className="container-profile">
          <Row>
            <div className="col-lg-12">
              <div className="form-group">
                <div className="img-profile-wrapper">
                  {avatar ? (
                    <>
                      <label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            selecionaThumb(event.target.files);
                          }}
                        />
                        {loadingImage ? (
                          <Spinner variant="primary" animation="border" />
                        ) : (
                          <div
                            className="img-profile-edit"
                            style={{
                              backgroundImage: `url(${localStorage.getItem(
                                "profileImage"
                              )})`,
                            }}
                          />
                        )}
                      </label>
                    </>
                  ) : (
                    <>
                      <label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            selecionaThumb(event.target.files);
                          }}
                        />
                        {loadingImage ? (
                          <Spinner variant="primary" animation="border" />
                        ) : (
                          <div className="avatar">
                            {nameToWelcome.substring(0, 1)}
                          </div>
                        )}
                      </label>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Row>
          <div className="img-profile-wrapper">
            <div className="separator-bar" />
          </div>
          <Row>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="name">Nome:</label>
                <input
                  required
                  className="form-control"
                  id="name"
                  placeholder="Edite seu nome"
                  defaultValue={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="name">E-mail:</label>
                <input
                  required
                  className="form-control"
                  id="email"
                  placeholder="Edite seu e-mail"
                  defaultValue={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="name">Tipo de perfil:</label>
                <div className="profile-type-select">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setType(1);
                    }}
                    className={
                      type === 1
                        ? "profile-type-button-active"
                        : "profile-type-button"
                    }
                  >
                    Particular
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setType(2);
                    }}
                    className={
                      type === 2
                        ? "profile-type-button-active"
                        : "profile-type-button"
                    }
                  >
                    República
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setType(3);
                    }}
                    className={
                      type === 3
                        ? "profile-type-button-active"
                        : "profile-type-button"
                    }
                  >
                    Hostel
                  </button>
                </div>
              </div>
            </div>
          </Row>
          {type === 2 || type === 3 ? (
            <>
              <Row>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="name">Endereço:</label>
                    <input
                      className="form-control"
                      id="address"
                      placeholder="Ex: Rua Dr Benjamim Machado 100"
                      defaultValue={address}
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-lg-12" style={{ marginBottom: 15 }}>
                  <div className="form-group">
                    <label htmlFor="state">Estado:</label>
                    <Select
                      name="state"
                      placeholder="Selecionar"
                      overrideStrings={configs}
                      noOptionsMessage={() => "Nenhum resultado encontrado"}
                      onChange={(event) => {
                        setState(event.value);
                      }}
                      // value={buscaValue(listState, state)}
                      defaultValue={montaValue(listState, state)}
                      // defaultInputValue={state}
                      isSearchable={true}
                      options={listState}
                    />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-lg-12" style={{ marginBottom: 15 }}>
                  <div className="form-group">
                    <label htmlFor="price">Cidade:</label>
                    <Select
                      name="city"
                      placeholder="Selecionar"
                      overrideStrings={configs}
                      noOptionsMessage={() => "Nenhum resultado encontrado"}
                      loadingMessage={() => "Procurando"}
                      className="basic-multi-select"
                      onChange={(event) => {
                        setCity(event.value);
                      }}
                      // value={montaValue(cityList, city)}
                      defaultValue={montaValue(cityList, city)}
                      isSearchable={true}
                      options={cityList}
                    />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="name">Telefone:</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="number"
                      placeholder="Telefone com DDD"
                      defaultValue={phone}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </div>
                </div>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Form>
      )}
      <Row>
        <div className="col-lg-12">
          <div className="form-group">
            <button
              onClick={() => {
                saveProfile();
              }}
              className="button-save-profile"
            >
              {saveLoading ? (
                <Spinner size="small" variant="light" />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )}
              Salvar
            </button>
          </div>
        </div>
      </Row>
    </>
  );
}
