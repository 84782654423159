import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import stringHelper from "../../helpers/StringHelper";
import "./index.css";

import logo from "../../assets/logo.svg";
import backgoundImg from "../../assets/background_img_1920.png";
import backgoundImg_1360 from "../../assets/background_img_1360.png";
import google_play from "../../assets/google_play.png";

import ModalCafofo from "../../components/Modal";
import ForgotPass from "../ForgotPass";

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [erroPass, setErroPass] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);
  const [error, setError] = useState(false);
  const [openModalForgot, setOpenModalForgot] = useState(false);

  async function authLogin() {
    const user_id = localStorage.getItem("user");
    if (user_id) {
      history.push("/dashboard");
    } else {
      return;
    }
  }

  useEffect(() => {
    authLogin();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const response = await api.post("/sessions", {
        email,
        password,
        action: "login",
      });
      const { id, status, name, token, favorites, avatar } = response.data;
      if (status == 1) {
        if (email === "" || !stringHelper.isEmail(email)) {
          setErroEmail(true);
          setLoadingLogin(false);
        } else {
          setErroEmail(false);
          if (password === "" || password.length < 8) {
            setErroPass(true);
            setLoadingLogin(false);
          } else {
            setLoadingLogin(false);
            localStorage.setItem("user", token);
            localStorage.setItem("id", id.toString());
            localStorage.setItem("name", name);
            // localStorage.setItem("favorites", favorites);
            localStorage.setItem("profileImage", avatar);
            history.push("/dashboard");
          }
        }
      } else {
        setError(true);
        setLoadingLogin(false);
      }
    } catch {
      setError(true);
      setLoadingLogin(false);
    }
  }
  return (
    <div className="loginScreenWrapper">
      <div className="loginWrapper">
        <div className="logoWrapper">
          <img src={logo} alt="Meu Cafofo" style={{ marginBottom: 50 }} />
          <p>
            Faça o seu
            <strong> login</strong>
          </p>
        </div>
        <form className="formWrapper" onSubmit={handleSubmit}>
          <label htmlFor="email">E-MAIL</label>
          <input
            className="input"
            type="email"
            id="email"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          {erroEmail ? (
            <div className="errorAlert">Digite um e-mail válido</div>
          ) : (
            <></>
          )}
          <label htmlFor="password">SENHA</label>
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <input
              className="input"
              type={showPass ? "text" : "password"}
              id="password"
              value={password}
              placeholder="Digite sua senha"
              onChange={(event) => setPassword(event.target.value)}
            />
            {showPass ? (
              <FontAwesomeIcon
                icon={faEyeSlash}
                color="#557cf2"
                className="iconEye"
                onClick={() => setShowPass(!showPass)}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEye}
                color="#557cf2"
                className="iconEye"
                onClick={() => setShowPass(!showPass)}
              />
            )}
          </div>
          {erroPass ? (
            <div className="errorAlert">
              A senha não pode ser vazia e precisa ter pelo menos 8 caracteres
            </div>
          ) : (
            <></>
          )}
          {error ? (
            <div className="errorAlert">Usuário ou senha inválido.</div>
          ) : (
            <></>
          )}
          <div className="buttons">
            <button className="btn btn-submit" type="submit">
              Entrar
            </button>
            <Link to="/register">
              <button className="btn btn-secondary" type="button">
                Cadastrar
              </button>
            </Link>
          </div>
          <div
            onClick={() => {
              setOpenModalForgot(true);
            }}
            className="forgot-pass"
          >
            Esqueceu a senha?
          </div>
        </form>
      </div>
      <div
        className="backgoundImg"
        style={{ backgroundImage: `url(${backgoundImg})` }}
      />
      <div
        className="backgoundImg1360"
        style={{ backgroundImage: `url(${backgoundImg_1360})` }}
      />
      <div
        className="downloadGoogle"
        style={{ backgroundImage: `url(${google_play})` }}
        onClick={(e) => {
          e.preventDefault();
          window.open(
            "https://play.google.com/store/apps/details?id=br.com.egend.meucafofo"
          );
        }}
      />
      <ModalCafofo
        className="modalFiltros"
        open={openModalForgot}
        close={() => {
          setOpenModalForgot(false);
        }}
        titulo={"Esqueci minha senha"}
        size="md"
      >
        <ForgotPass />
      </ModalCafofo>
    </div>
  );
}
