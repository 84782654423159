import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { Offcanvas } from "react-bootstrap";
import { errorToast, successToast } from "../../components/Toast";

import usersService from "../../services/UsersService";
import Loading from "../../components/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

import EditProfile from "../../pages/EditProfile";

export default function MenuLateral({ history, handleClose, show, user }) {
  const [nameToWelcome, setNameToWelcome] = useState("");
  const [userId, setUserId] = useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    function load() {
      setUserId(localStorage.getItem("id"));
    }
    try {
      usersService.getUsersData().then((res) => {
        setLoading(false);
        localStorage.getItem(String(res.data.name));
        setNameToWelcome(res.data.name);
        localStorage.getItem(String(res.data.avatar));
        setProfileImage(res.data.avatar);
      });
    } catch {
      setLoading(false);
      errorToast.fire({
        title: "Erro ao buscar os dados, tente novamente",
      });
    }
    load();
  }, []);

  function logout() {
    localStorage.clear();
  }

  return (
    <>
      {loading ? (
        <Loading color="primary" />
      ) : (
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <div className="menuHeaderWrapper">
              <div style={{ width: "90%" }}>
                <Offcanvas.Title>Bem-Vindo(a)</Offcanvas.Title>
                <h3 className="nameToWelcomeMenu">{nameToWelcome}</h3>
              </div>
              {profileImage ? (
                <img className="avatar" src={profileImage} />
              ) : nameToWelcome ? (
                <div className="avatar">{nameToWelcome.substring(0, 1)}</div>
              ) : (
                <></>
              )}
            </div>
          </Offcanvas.Header>
          {edit ? (
            <Offcanvas.Body>
              <EditProfile
                setNameTo={setNameToWelcome}
                edit={edit}
                setEdit={setEdit}
              />
            </Offcanvas.Body>
          ) : !user ? (
            <Offcanvas.Body>
              <div className="logoutWrapper">
                <Link to="/login">
                  <div className="logoutButton">
                    <FontAwesomeIcon
                      icon={faSignInAlt}
                      size={"1x"}
                      color="#557cf2"
                      style={{ marginRight: 10 }}
                    />
                    <div className="textMenuSair">Logar</div>
                  </div>
                </Link>
              </div>
              <div className="footerWrapper">
                <div className="textMenuFooter">
                  Nos siga nas nossas redes sociais
                </div>
                <div className="footerIconsWrapper">
                  <div
                    className="icons"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.instagram.com/oficialmeucafofo/"
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size={"2x"}
                      color="#C13584"
                    />
                  </div>
                  <div
                    className="icons"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.facebook.com/oficialmeucafofo/");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFacebookSquare}
                      size={"2x"}
                      color="#3b5998"
                    />
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          ) : (
            <Offcanvas.Body>
              <div className="menuItens">
                <Link to="/user_vacancies">
                  <div className="textMenu">Vagas cadastradas por mim</div>
                </Link>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText("www.meucafofo.com");
                    successToast.fire({
                      title: `Mande o link copiado para os seus amigos`,
                    });
                  }}
                >
                  <div className="textMenu">Convidar amigos</div>
                </div>
                <Link to="/chats">
                  <div className="textMenu">Chats</div>
                </Link>
                <div
                  onClick={() => {
                    window.location = "mailto:contato@meucafofo.com";
                  }}
                >
                  <div className="textMenu">Nos dê o seu feedback</div>
                </div>
                <div
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  <div className="textMenu">Editar seu perfil</div>
                </div>
              </div>
              <div className="logoutWrapper">
                <Link
                  to="/login"
                  onClick={() => {
                    logout();
                  }}
                >
                  <div className="logoutButton">
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      size={"1x"}
                      color="red"
                      style={{ marginRight: 10 }}
                    />
                    <div className="textMenuSair">Sair</div>
                  </div>
                </Link>
              </div>
              <div className="footerWrapper">
                <div className="textMenuFooter">
                  Nos siga nas nossas redes sociais
                </div>
                <div className="footerIconsWrapper">
                  <div
                    className="icons"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.instagram.com/oficialmeucafofo/"
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size={"2x"}
                      color="#C13584"
                    />
                  </div>
                  <div
                    className="icons"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.facebook.com/oficialmeucafofo/");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFacebookSquare}
                      size={"2x"}
                      color="#3b5998"
                    />
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          )}
        </Offcanvas>
      )}
    </>
  );
}
