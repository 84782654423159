import React, { useEffect, useState } from "react";
import "./index.css";
import { Spinner } from "react-bootstrap";

export default function Loading({ color }) {
  return (
    <div className="SpinnerWrapper">
      <Spinner
        style={{ marginRight: 2 }}
        animation="grow"
        variant={color ? color : "light"}
        size="sm"
      />
      <Spinner
        style={{ marginRight: 2 }}
        animation="grow"
        variant={color ? color : "light"}
        size="sm"
      />
      <Spinner size="sm" animation="grow" variant={color ? color : "light"} />
    </div>
  );
}
