import React from "react";

import "./index.css";

export default function PrivacyPolice() {
  return (
    <div>
      <div className="title">Políticas de privacidade</div>
      <div className="lastUpdate">DATA DA ÚLTIMA ATUALIZAÇÃO: 17/07/2023</div>

      <div className="introduction">
        O Meu Cafofo (adiante "Meu Cafofo", ou "Nós") entende a importância e o
        dever de respeitar a privacidade e a segurança das informações de todos
        os usuários (adiante "Usuário", ou "Você") que acessam o site e/ou
        aplicativo Meu Cafofo (adiante "Plataforma"). Por isso, nos
        comprometemos a adotar as melhores práticas para proteger a sua
        privacidade e segurança, de acordo com as leis e regulamentos
        aplicáveis. Inclusive conforme a Lei Geral de Proteção de Dados (Lei nº
        13.709/2018) - LGPD.
      </div>

      <div className="attention">
        PEDIMOS QUE LEIA COM ATENÇÃO ESTA POLÍTICA DE PRIVACIDADE, QUE DESCREVE
        COMO TRATAMOS AS INFORMAÇÕES PESSOAIS QUE VOCÊ NOS FORNECE OU QUE
        COLETAMOS QUANDO VOCÊ ACESSA E UTILIZA A PLATAFORMA. AO ACEITAR ESTA
        POLÍTICA DE PRIVACIDADE, VOCÊ CONCORDA COM AS PRÁTICAS DE TRATAMENTO DE
        INFORMAÇÕES DESCRITAS NESTE DOCUMENTO.
      </div>

      <div className="subTitle">Informações Coletadas</div>
      <div className="description">
        As informações coletadas pelo Meu Cafofo são as informações que o
        usuário disponibilizar no registro de uma nova conta, são eles:
      </div>
      <div className="points">Nome</div>
      <div className="points">E-mail</div>
      <div className="points">Senha para acesso a aplicação</div>

      <div className="description">
        Caso o usuário tenha interesse em atualizar o perfil, além das
        informações anteriormente citadas, serão coletados dados como:
      </div>
      <div className="points">Foto de perfil</div>
      <div className="points">Pronome de gênero</div>
      <div className="points">Tipo do perfil</div>
      <div className="points">Nome da república (se aplicar)</div>
      <div className="points">Endereço completo</div>
      <div className="points">Celular</div>

      <div className="description">
        Caso o usuário tenha interesse em cadastrar uma vaga, além das
        informações anteriormente citadas, serão coletados dados das vagas que
        serão inseridas:
      </div>
      <div className="points">Fotos da vaga</div>
      <div className="points">Nome da vaga</div>
      <div className="points">Tipo da vaga</div>
      <div className="points">Valor</div>
      <div className="points">Tempo de locação (se aplicar)</div>
      <div className="points">Celular</div>
      <div className="points">Benefícios</div>
      <div className="points">Descrição</div>
      <div className="points">Endereço completo</div>
      <div className="points">Nome da república (se aplicar)</div>
      <div className="points">Exclusividade de gênero da vaga</div>
      <div className="points">Video</div>

      <div className="description">
        Essas informações serão usadas apenas para os devidos fins já descritos
        no aplicativo, que são cadastro de novo usuário e cadastro de nova vaga.
      </div>

      <div className="subTitle">Armazenamento</div>
      <div className="description">
        Todas as informações coletadas nos cadastros serão salvas no nosso banco
        de dados, a informação senha é criptografada em nosso servidor.
      </div>

      <div className="subTitle">Faixa Etária</div>
      <div className="description">
        O Meu Cafofo é indicado para qualquer classificação etária.
      </div>
    </div>
  );
}
