import React, { useState, useEffect, useCallback, useRef } from "react";
import "../New/index.css";
import { useHistory } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Swal from "sweetalert2";
import camera from "../../assets/camera.svg";

import RequiredSpan from "../../components/RequiredSpan";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { Form, Spinner, Row } from "react-bootstrap";
import Gallery from "./Gallery";
import ReactCrop from "react-image-crop";
import ModalCafofo from "../../components/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLevelUpAlt, faCheck } from "@fortawesome/free-solid-svg-icons";

import api from "../../services/api";
import { errorToast, successToast } from "../../components/Toast";
import ibge from "../../services/ibge";
import imgur from "../../services/imgur";
import vacancyService from "../../services/VacancyService";

export default function New({
  history,
  _price,
  _benefits,
  _name,
  _description,
  _city,
  _state,
  _republic,
  thumbnailURL,
  _whatsapp,
  _videoURL,
  _gender,
  _showWpp,
  editing,
  idVacancy,
}) {
  const [user, setUser] = useState("");
  const [thumbnail, setThumbnail] = useState(
    thumbnailURL ? JSON.parse(thumbnailURL) : []
  );
  const [price, setPrice] = useState(_price ? _price.toString() : "");
  const [name, setName] = useState(_name ? _name : "");
  const [description, setDescription] = useState(
    _description ? _description : ""
  );
  const [videoURL, setVideoURL] = useState(_videoURL ? _videoURL : "");
  const [gender, setGender] = useState(_gender ? _gender : "");
  const [city, setCity] = useState(_city ? _city : "");
  const [cityList, setCityList] = useState([]);
  const [state, setState] = useState(_state ? _state : "");
  const [republic, setRepublic] = useState(_republic ? _republic : "");
  const [benefits, setBenefits] = useState(
    _benefits ? JSON.parse(_benefits) : []
  );
  const [whatsapp, setWhatsapp] = useState(_whatsapp ? _whatsapp : "");
  const [showWpp, setShowWpp] = useState(_showWpp ? _showWpp : false);
  const [error, setError] = useState(false);
  const [wwpError, setWppError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshGallery, setRefreshGallery] = useState(false);
  const [loadingNewVacancy, setLoadingNewVacancy] = useState(false);
  const [loadingEditing, setLoadingEditing] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useHistory();

  function refreshPage() {
    window.location.reload(false);
  }

  const isDisabled = true;

  const [benefitsList, setBenefitsList] = useState([]);
  const [genderList, setGenderList] = useState([
    { value: "Masculina", label: "Masculino" },
    { value: "Feminina", label: "Feminino" },
    { value: "Unisex", label: "Unisex" },
  ]);
  const [listState, setListState] = useState([
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
  ]);
  const configs = {
    selectSomeItems: "Selecione",
    allItemsAreSelected: "Todos itens selecionados",
    selectAll: "Selecionar todos",
    search: "Pesquisar",
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      errorToast.fire({
        title: "Faça login para usar essa funcionalidade",
      });
      history.push("/login");
    }
  }, []);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  async function handleSubmit(event) {
    setLoadingNewVacancy(true);
    if (state === "") {
      setError(true);
      return;
    } else {
      setError(false);
      if (city === "") {
        setWppError(true);
      } else {
        setWppError(false);
        event.preventDefault();
        let dados = {
          price: price == "" ? null : price,
          benefits,
          name,
          description,
          city,
          state,
          republic,
          thumbnail_url:
            thumbnail.length == 0 ? null : JSON.stringify(thumbnail),
          whatsapp,
          videoURL,
          gender: gender === "Selecione" ? "" : gender,
          show_whatsapp: showWpp,
        };
        if (editing) {
          const response = await api.put(`/vacancies/${idVacancy}`, dados, {
            headers: {
              Authorization: localStorage.getItem("user"),
            },
          });
          if (response.status == 200) {
            successToast.fire({ title: `Edição efetuada` });
            history.push("Dashboard");
            setTimeout(() => {
              setLoadingNewVacancy(false);
            }, 2000);
          } else {
            errorToast.fire({
              title: `Erro ao efetuar a edição da vaga, tente novamente.`,
            });
            setLoadingNewVacancy(false);
          }
        } else {
          const response = await api.post(`/vacancies`, dados, {
            headers: {
              Authorization: localStorage.getItem("user"),
            },
          });
          if (response.status == 201) {
            successToast.fire({ title: `Cadastro da vaga efetuado` });
            history.push("Dashboard");
            setTimeout(() => {
              setLoadingNewVacancy(false);
            }, 2000);
          } else {
            errorToast.fire({
              title: `Erro ao efetuar cadastro da vaga, tente novamente.`,
            });
            setLoadingNewVacancy(false);
          }
        }
      }
    }
  }
  useEffect(() => {
    ibge.buscarMunicipiosPorUF(state).then((response) => {
      let listaCidades = [];
      response.data.map((city, index) =>
        listaCidades.push({ label: city.nome, id: city.id, value: city.nome })
      );
      setCityList(listaCidades);
    });
  }, [state]);

  useEffect(() => {
    vacancyService.getBenefits().then((res) => {
      let benefits = [];
      Object.values(res.data).forEach((benefit) => {
        const item = {
          value: benefit.name,
          label: benefit.name,
        };
        benefits.push(item);
      });
      setBenefitsList(benefits);
    });
  }, []);

  function handleDeleteThumb(id) {
    Swal.fire({
      title: "Atenção!",
      text: "Você tem certeza que deseja excluir esta imagem?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        selecionaThumb(id, false);
      }
    });
  }

  async function selecionaThumb(id, nova, files) {
    let imageList = thumbnail;
    if (String(id) !== "null") {
      if (String(id) === "0") {
        imageList.shift();
        setThumbnail(imageList);
        setRefreshGallery(!refreshGallery);
      } else {
        imageList.splice(Number(id), 1);
        setThumbnail(imageList);
        setRefreshGallery(!refreshGallery);
      }
    } else if (nova) {
      setLoading(true);
      Object.values(files).forEach((fileItem, index) => {
        try {
          let base64String = "";
          var file = fileItem;
          var reader = new FileReader();
          reader.onload = function () {
            base64String = reader.result
              .replace("data:", "")
              .replace(/^.+,/, "");

            imgur.uploadApiCafofo(base64String).then((response) => {
              imageList.push({ link: response.data.data.link });
              setRefreshGallery(!refreshGallery);
              setLoading(false);
            });
            setThumbnail(imageList);
          };
          reader.readAsDataURL(file);
        } catch {
          setLoading(false);
          errorToast.fire({
            title: `Erro ao enviar a foto, tente novamente.`,
          });
        }
      });
    }
  }

  function buscaValue(lista, selecionado) {
    lista.map((item) => {
      if (item.value === String(selecionado)) {
        return item;
      } else {
        return "";
      }
    });
  }

  function montaValue(lista, selecionado) {
    const index = lista.findIndex((item) => item.value == selecionado);
    return lista[index];
  }

  function montaValueMultiSelect() {
    const index = benefitsList
      .filter((item) => benefits.includes(item.value))
      .map((status) => {
        return {
          value: status.value,
          label: status.label,
        };
      });
    return index;
  }

  function onChangeSelect(value, SetState) {
    let arr = [];

    value.forEach((item) => {
      arr.push(item.value);
    });

    SetState(arr);
  }

  if (isDisabled) {
    return Swal.fire({
      title: "Atenção!",
      text: "Pagina desabilitada na versão web, baixe nosso app para cadastrar uma vaga!",
      icon: "warning",
      confirmButtonText: "Ok!",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate.push("/dashboard");
        refreshPage();
      }
    });
  }

  return (
    <Form className="containerNew" onSubmit={handleSubmit}>
      <Row>
        <div className="col-lg-12">
          <div className="form-group">
            <label
              className={thumbnail ? "has-thumbnail" : ""}
              id="thumbnail"
              // style={{ backgroundImage: `url(${preview})` }}
            >
              <input
                className="inputNew"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  selecionaThumb(null, true, event.target.files);
                }}
              />
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <img src={camera} alt="Selecione a imagem" />
              )}
            </label>
            <span>
              *Dica você pode cadastrar mais de uma foto(e nos recomendamos)!!
            </span>
          </div>
        </div>
      </Row>
      <Gallery
        refreshGallery={refreshGallery}
        setRefreshGallery={setRefreshGallery}
        thumbnail={thumbnail}
        handleDeleteThumb={handleDeleteThumb}
      />
      <Row>
        <div className="col-lg-12">
          <div className="form-group">
            <label htmlFor="name">
              Título: <RequiredSpan />
            </label>
            <input
              required
              className="form-control"
              id="name"
              placeholder="Digite o título da vaga"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12">
          <div className="form-group">
            <label htmlFor="price">Preço (mês):</label>
            <input
              className="form-control"
              id="price"
              placeholder="Digite o valor mensal"
              value={price}
              type="number"
              onChange={(event) => setPrice(event.target.value)}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12">
          <div className="form-group">
            <label htmlFor="whatsapp">
              WhatsApp (Digite o DDD): <RequiredSpan />
            </label>
            <div className="input-wpp-wrapper">
              <input
                className="form-control"
                id="whatsapp"
                required
                type="number"
                placeholder="Digite o seu WhatsApp"
                value={whatsapp}
                maxLength={11}
                minLength={10}
                onChange={(event) => setWhatsapp(event.target.value)}
              />
              <div
                className="checkbox"
                onClick={() => {
                  setShowWpp(!showWpp);
                }}
              >
                <div className="checkbox-border">
                  {showWpp ? (
                    <FontAwesomeIcon icon={faCheck} color="#C1DEAE" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="show-wpp-tip">
              {showWpp ? (
                <p>O seu WhatsApp será exibido</p>
              ) : (
                <>
                  <p>Deseja exibir o seu WhatsApp?</p>
                  <FontAwesomeIcon icon={faLevelUpAlt} color="#557cf2" />
                </>
              )}
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <div className="col-lg-12">
          <div className="form-group">
            <label htmlFor="description">
              Descrição: <RequiredSpan />
            </label>
            <textarea
              className="form-control"
              id="description"
              placeholder="Digite uma descrição"
              cols="30"
              required
              rows="4"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12" style={{ marginBottom: 15 }}>
          <div className="form-group">
            <label htmlFor="state">
              Estado: <RequiredSpan />
            </label>
            <Select
              name="state"
              placeholder="Selecionar"
              overrideStrings={configs}
              noOptionsMessage={() => "Nenhum resultado encontrado"}
              onChange={(event) => {
                setState(event.value);
              }}
              // value={buscaValue(listState, state)}
              defaultValue={montaValue(listState, state)}
              // defaultInputValue={state}
              isSearchable={true}
              options={listState}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12" style={{ marginBottom: 15 }}>
          <div className="form-group">
            <label htmlFor="price">
              Cidade: <RequiredSpan />
            </label>
            <Select
              name="city"
              placeholder="Selecionar"
              overrideStrings={configs}
              noOptionsMessage={() => "Nenhum resultado encontrado"}
              loadingMessage={() => "Procurando"}
              className="basic-multi-select"
              onChange={(event) => {
                setCity(event.value);
              }}
              value={montaValue(cityList, city)}
              defaultValue={montaValue(cityList, city)}
              isSearchable={true}
              options={cityList}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12" style={{ marginBottom: 15 }}>
          <div className="form-group">
            <label htmlFor="benefits">Benefícios:</label>
            <MultiSelect
              name="benefits"
              options={benefitsList}
              onChange={(event) => {
                onChangeSelect(event, setBenefits);
              }}
              tabIndex={0}
              value={montaValueMultiSelect()}
              overrideStrings={configs}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12">
          <div className="form-group">
            <label htmlFor="republic">É república? Digite o nome:</label>
            <input
              className="form-control"
              id="republic"
              placeholder="Digite o nome da república"
              value={republic}
              onChange={(event) => setRepublic(event.target.value)}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12" style={{ marginBottom: 15 }}>
          <div className="form-group">
            <label htmlFor="gender">
              Exclusividade de gênero (M,F ou Unisex):
            </label>
            <Select
              name="gender"
              placeholder="Selecionar"
              overrideStrings={configs}
              noOptionsMessage={() => "Nenhum resultado encontrado"}
              onChange={(event) => {
                setGender(event.value);
              }}
              isSearchable={true}
              // value={buscaValue(genderList, gender)}
              defaultValue={montaValue(genderList, gender)}
              options={genderList}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12">
          <div className="form-group">
            <label htmlFor="video">VídeoURL (YouTube):</label>
            <input
              className="form-control"
              id="video"
              placeholder="Digite a URL do vídeo"
              value={videoURL}
              onChange={(event) => setVideoURL(event.target.value)}
            />
          </div>
        </div>
      </Row>
      <div className="buttonsRegister">
        {editing ? (
          loadingNewVacancy ? (
            <button className="btn btn-secondary btn-full" disabled>
              <Spinner animation="border" variant="light" size="sm" />
            </button>
          ) : (
            <button className="btn btn-secondary btn-full" type="submit">
              Editar
            </button>
          )
        ) : loadingNewVacancy ? (
          <button className="btn btn-secondary btn-full" disabled>
            <Spinner animation="border" variant="light" size="sm" />
          </button>
        ) : (
          <button className="btn btn-secondary btn-full" type="submit">
            Cadastrar
          </button>
        )}
      </div>
      <ModalCafofo
        className="modalFiltros"
        open={openModal}
        close={() => {
          setOpenModal(false);
        }}
        titulo={"Cortar imagem"}
      >
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(newCrop) => {
            setCrop(newCrop);
          }}
          onComplete={(c) => setCompletedCrop(c)}
        />
      </ModalCafofo>
    </Form>
  );
}
