import React from "react";
import api from "../services/api";

const usersService = {
  getUsersData: async () => {
    return await api.get(`/users`, {
      headers: {
        Authorization: localStorage.getItem("user"),
      },
    });
  },
  putUsersData: async (dados) => {
    return await api.put(`/users`, dados, {
      headers: {
        Authorization: localStorage.getItem("user"),
      },
    });
  },
};

export default usersService;
