import React, { useEffect, useState } from "react";
import "./index.css";
import { Row, Col } from "react-bootstrap";
import vacancyService from "../../../services/VacancyService";
import { errorToast, successToast } from "../../../components/Toast";
import Loading from "../../../components/Loading";

export default function ReportVacancy({ vacancyId, user, token, close }) {
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  function handleReport(vacancy) {
    setLoading(true);
    if (description === "") {
      errorToast.fire({ title: `Nos diga o motivo!!` });
      setLoading(false);
    } else {
      try {
        vacancyService
          .vacancyReport(vacancy, user, description, token)
          .then((res) => {
            successToast.fire({ title: `Vaga reportada com sucesso` });
            setDescription("");
            setLoading(false);
            close();
          });
      } catch {
        errorToast.fire({ title: `Erro ao reportar, tente novamente` });
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Row>
        <Col>
          <textarea
            name="description"
            className="form-control"
            rows={4}
            multiple
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required
            placeholder="Nos diga o motivo..."
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ justifyContent: "flex-end", display: "flex" }}>
          <button
            className="reportButton"
            onClick={() => {
              handleReport(vacancyId);
            }}
            disabled={loading}
          >
            {loading ? <Loading /> : "Reportar"}
          </button>
        </Col>
      </Row>
    </>
  );
}
