import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import logo from "../../assets/logo.svg";
import ModalCafofo from "../../components/Modal";
import Newspaper from "./Newspaper";
import arrNews from "./news.json";

import { Popover, OverlayTrigger, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUserCircle,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import { errorToast } from "../Toast";

import MenuLateral from "../MenuLateral";

export default function MenuTopo({ history }) {
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newsSeen, setNewsSeen] = useState(0);
  const user = localStorage.getItem("user");

  function seenNum(item) {
    let arr = [];
    if (item.seen === false) {
      arr.push(item);
    }
    console.log(arr);
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Novidades</Popover.Header>
      <Popover.Body className="new_body">
        {arrNews.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => {
                seenNum(item);
                item.seen = true;
                console.log(`item.seen`, item.seen);
              }}
              className="news_box"
            >
              {item.description}
            </div>
          );
        })}
      </Popover.Body>
    </Popover>
  );

  function handleShow() {
    setShow(true);
  }
  const handleClose = () => setShow(false);

  return (
    <>
      <nav className="cafofo-menu">
        <div className="logo">
          <Link to="/dashboard">
            <picture>
              <img src={logo} alt="Meu Cafofo" />
            </picture>
          </Link>
        </div>
        <div className="right-nav">
          <div className="newVacancyTop">
            <Link
              to={!user ? "/login" : "/new"}
              className="newVacancyButtonTop"
            >
              <button className="btnNewVacancy" type="button">
                {!user ? "Fazer Login" : "Cadastrar nova vaga"}
              </button>
            </Link>
          </div>
          <div className="info-nav">
            <div className="online" onClick={handleShow}>
              <FontAwesomeIcon
                icon={faBars}
                className="iconBar"
                color="#7F7C82"
              />
              <FontAwesomeIcon
                icon={faUserCircle}
                className="iconUser"
                color="#7F7C82"
              />
            </div>
            {/* <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
            >
              <div className="newspaper">
                <FontAwesomeIcon icon={faNewspaper} color="#7F7C82" />
                <Badge className="new_notions" bg="danger">
                  {1}
                </Badge>
              </div>
            </OverlayTrigger> */}
          </div>
        </div>
      </nav>
      <MenuLateral
        history={history}
        handleClose={handleClose}
        show={show}
        user={user}
      />
      {/* <ModalCafofo
        className="modalFiltros"
        open={openModal}
        close={() => {
          setOpenModal(false);
        }}
        titulo={"Detalhes"}
        size="md"
      >
        <Newspaper />
      </ModalCafofo> */}
    </>
  );
}
