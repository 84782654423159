function apenasNumeros(value) {
  let valor = 0;
  if (value != "" && value != null && value != undefined) {
    valor = value.toString().replace(/[^0-9]/g, "");
  }
  return valor;
}

function celularMask(value) {
  if (value == undefined || value.substring(0, 1) === "*") {
    return value;
  }
  var r = value.toString().replace(/\D/g, "");
  r = r.toString().replace(/^0/, "");
  if (r.length > 10) {
    r = r.toString().replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    r = r.toString().replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.toString().replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    r = r.toString().replace(/^(\d*)/, "($1");
  }
  return r;
}

function horaMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1:$2")
    .replace(/(:\d{2})\d+?$/, "$1");
}

function cepMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
}

function realMoeda(value) {
  if (value != "" && value != null && value != undefined) {
    let str = String(value);

    let retorno = str.replace(/\D/g, "").replace(/([0-9]{2})$/g, ",$1");

    if (retorno.length > 6)
      retorno = retorno.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return retorno;
  } else {
    return value;
  }
}

const formatarParaMascaraMoeda = function (value) {
  if (value == undefined) {
    value = "0";
  }
  return Number(value).toFixed(2).toString().replace(".", ",");
};

const limpaMascaraMoeda = function (value) {
  if (value == undefined) {
    value = "0";
  }
  // return value;
  return value.replace("R$ ", "").replaceAll(".", "");
};

const formatarParaMascaraMoedaComSeparadorDeMilhares = function (value) {
  if (value == undefined) {
    return "PREÇO A NEGOCIAR";
  } else if (String(value).substring(0, 1) === "*") {
    return value;
  }
  let valor = Number(value);
  if (Math.abs(valor) < 1000) {
    return formatarParaMascaraMoeda(value);
  }
  let strComDecimais = Number(value).toFixed(2).toString();
  let arrInteiroSeparadoDosDecimais = strComDecimais.split(".");
  arrInteiroSeparadoDosDecimais[0] = arrInteiroSeparadoDosDecimais[0]
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    .join(".")
    .split("")
    .reverse()
    .join("");
  return arrInteiroSeparadoDosDecimais.join(",");
};

const formatarDeMascaraMoeda = function (strValue) {
  if (strValue || false) {
    return Number(strValue.toString().replace(/\./g, "").replace(",", "."));
  }

  return null;
};

const formatarParaMascaraMoedaComSeparadorRomano = function (value) {
  let valorNumerico = Number(value || 0);
  let valorComVirgula =
    formatarParaMascaraMoedaComSeparadorDeMilhares(valorNumerico);
  let valorExplodido = valorComVirgula.split(".");
  if (valorNumerico == 0) {
    return valorComVirgula;
  }
  if (valorNumerico >= 1000000000) {
    valorExplodido.pop();
    valorExplodido.pop();
    valorExplodido.pop();

    return valorExplodido.join(".").toString() + "BI";
  }
  if (valorNumerico >= 1000000) {
    valorExplodido.pop();
    valorExplodido.pop();

    return valorExplodido.join(".").toString() + "MI";
  }
  if (Math.abs(valorNumerico) > 1000) {
    valorExplodido.pop();
    return valorExplodido.join(".").toString() + "K";
  }

  return valorComVirgula;
};

const validaCPF = function (strCPF) {
  strCPF = strCPF.replace(/\D/g, "");
  let soma = 0;
  let resto;
  if (strCPF == "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

const validaHorario = function (value) {
  return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value);
};

export {
  horaMask,
  celularMask,
  cepMask,
  realMoeda,
  apenasNumeros,
  formatarDeMascaraMoeda,
  formatarParaMascaraMoeda,
  formatarParaMascaraMoedaComSeparadorRomano,
  formatarParaMascaraMoedaComSeparadorDeMilhares,
  validaCPF,
  validaHorario,
  limpaMascaraMoeda,
};
