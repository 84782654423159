import React, { useState, useMemo, useEffect } from "react";
import "./index.css";

import SimpleImageSlider from "react-simple-image-slider";

import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { errorToast } from "../../components/Toast";

import api from "../../services/api";

export default function UserVacancies({ history }) {
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user_id = localStorage.getItem("user");
    if (user_id) {
      loadDash(user_id);
    } else {
      errorToast.fire({
        title: "Faça login para usar essa funcionalidade",
      });
      history.push("/login");
    }

    async function loadDash(user) {
      setLoading(true);
      const response = await api.get("/dashboard", {
        headers: {
          Authorization: user,
        },
      });
      setLoading(false);
      setVacancies(response.data);
    }
  }, []);

  async function openDetail(item) {
    window.open(`/vacancy/${item.id}`);
  }

  function ImageSliderData(thumb) {
    let arr = [];
    JSON.parse(thumb).forEach((item) => {
      arr.push({ url: item.link });
    });

    return arr;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : vacancies != "" ? (
        <ul className="vacancy-list">
          {vacancies.map((vacancy) => {
            return (
              <li
                key={vacancy.id}
                onClick={() => {
                  openDetail(vacancy);
                }}
              >
                {vacancy.thumbnail_url === null ||
                vacancy.thumbnail_url === "" ||
                vacancy.thumbnail_url === undefined ||
                vacancy.thumbnail_url === "[]" ||
                vacancy.thumbnail_url.length <= 0 ? (
                  <img
                    className="imgBox"
                    src={"https://i.imgur.com/iuSUlvZ.png"}
                  />
                ) : (
                  <SimpleImageSlider
                    width={300}
                    height={200}
                    images={ImageSliderData(vacancy.thumbnail_url)}
                    showBullets={true}
                    showNavs={true}
                    navSize={30}
                    navMargin={10}
                    style={{ borderRadius: 11 }}
                    showBullets={false}
                  />
                )}
                <div className="nameGenderWrapper">
                  <strong className="vacancyName">{vacancy.name}</strong>
                  {vacancy.gender === "Masculina" ? (
                    <FontAwesomeIcon icon={faMars} color="#87CEEB" />
                  ) : vacancy.gender === "Feminina" ? (
                    <FontAwesomeIcon icon={faVenus} color="#FF99CC" />
                  ) : vacancy.gender === "Unisex" ? (
                    <FontAwesomeIcon icon={faVenusMars} color="#999900" />
                  ) : (
                    <></>
                  )}
                </div>
                <span>
                  {vacancy.price
                    ? `R$ ${vacancy.price} /mês`
                    : "PREÇO A NEGOCIAR"}
                </span>
                <span>
                  {vacancy.city} - {vacancy.state}
                </span>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="vacancyNotFound">
          <div className="vacancyNotFoundBorder">
            <span className="textVacancyNotFound">
              Você ainda não cadastrou nenhuma vaga.
            </span>
          </div>
        </div>
      )}
    </>
  );
}
