import React, { useState, useEffect } from "react";
import noChatImg from "../../assets/noChat.png";
import "./index.css";

export default function NoChat({}) {
  return (
    <div className="noChatWrapper">
      <img src={noChatImg} className="noChatImg" />
      <h1>Nenhum chat selecionado...</h1>
      <h5 style={{ textAlign: "center" }}>
        Selecione algum chat no menu esquerdo, ou ache alguma vaga com que
        deseje iniciar o chat
      </h5>
      <div className="noChatBar" />
      <h6>
        Já conhece o nosso app mobile?! Não deixe de baixar o Meu Cafofo no
        <a
          href="https://play.google.com/store/apps/details?id=br.com.egend.meucafofo"
          className="noChatGooglePlay"
          target="_blank"
        >
          {" "}
          Google Play
        </a>
        .
      </h6>

      <h6>
        Nos siga no
        <a
          href="https://www.instagram.com/oficialmeucafofo/"
          className="noChatInsta"
          target="_blank"
        >
          {" "}
          instagram
        </a>{" "}
        para não perder nenhuma novidade.
      </h6>
    </div>
  );
}
