import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { isMobile } from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEllipsisV,
  faInbox,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";

import Swal from "sweetalert2";
import { errorToast, successToast } from "../../components/Toast";
import Loading from "../../components/Loading";

import ChatSelecionado from "../ChatSelecionado";
import NoChat from "../../components/NoChat";
import chatService from "../../services/ChatService";

import moment from "moment";

import "./index.css";

export default function Chats({ history }) {
  let location = useLocation();
  const [listChats, setListChats] = useState([]);
  const [chatSearch, setChatSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [primeiro, setPrimeiro] = useState(false);
  const [nameToWelcome, setNameToWelcome] = useState("");
  const [userId, setUserId] = useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const [chatSelecionado, setChatSelecionado] = useState(null);

  useEffect(() => {
    function load() {
      setNameToWelcome(localStorage.getItem("name"));
      setProfileImage(localStorage.getItem("profileImage"));
      setUserId(localStorage.getItem("id"));
    }
    load();
  }, []);

  async function pendingChats() {
    setPrimeiro(localStorage.getItem(`loading`));
    setLoading(true);
    Promise.all([
      chatService.buscaChats(),
      chatService.buscaChatsReceived(),
    ]).then((res) => {
      const index = res[0].data.slice();
      const oppend = res[1].data.slice();
      let list = index != undefined ? index.concat(oppend) : oppend;
      setListChats(list);
      setLoading(false);
      localStorage.setItem(`loading`, "0");
    });
  }

  useEffect(() => {
    if (localStorage.getItem("user")) {
      function load() {
        localStorage.setItem(`loading`, "1");

        setPrimeiro(localStorage.getItem(`loading`));
      }
      load();
    } else {
      errorToast.fire({
        title: "Faça seu login ou cadastro antes",
      });
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    pendingChats();
  }, [refresh]);

  useEffect(() => {
    var myinterval = setInterval(() => {
      pendingChats();
    }, 10000);
    return () => {
      clearInterval(myinterval);
    };
  }, []);

  useEffect(() => {
    if (location.state) {
      getMessages(
        location.state.data.id,
        location.state.item.name,
        location.state.item.thumbnail_url,
        location.state.data.vacancy
      );
    }
  }, []);

  function getMessages(idChat, name, thumbnail, idVacancy) {
    const user_id = localStorage.getItem("id");
    if (user_id) {
      setChatSelecionado(
        <ChatSelecionado
          idChat={idChat}
          name={name}
          thumbnail={thumbnail}
          idVacancy={idVacancy}
          userId={user_id}
        />
      );
    }
  }

  function deleteChat(id) {
    Swal.fire({
      title: "Atenção!",
      text: "Você tem certeza que deseja excluir o chat?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          setLoadingDelete(true);
          chatService.excluirChat(id).then(() => {
            setLoadingDelete(false);
            successToast.fire({
              title: `Chat exluido com sucesso`,
            });
            setRefresh(!refresh);
            setChatSelecionado(null);
          });
        } catch {
          setLoadingDelete(false);
          errorToast.fire({
            title: `Erro ao excluir o chat, tente novamente!`,
          });
        }
      }
    });
  }

  function menu(vacancyId) {
    return (
      <>
        <div className="menuRegisto">
          <OverlayTrigger
            key="buttonMenuRegisto"
            placement="top"
            overlay={<Tooltip id={`tooltip-editar`}>Opções</Tooltip>}
          >
            <DropdownButton
              id="DropRegistro"
              size="sm"
              title={
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  style={{ fontSize: 15 }}
                  color={"#557CF2"}
                />
              }
            >
              <Dropdown.Item
                className="opcaoMenu"
                onClick={() => {
                  deleteChat(vacancyId);
                }}
              >
                <div className="opcaoExcluir">
                  {loadingDelete ? (
                    <Spinner variant="primary" />
                  ) : (
                    <FontAwesomeIcon icon={faTrash} />
                  )}
                  <span className="tituloExcluir">Excluir</span>
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </OverlayTrigger>
        </div>
      </>
    );
  }

  return (
    <>
      {isMobile ? (
        chatSelecionado ? (
          chatSelecionado
        ) : (
          <div className="globalChatsWrapperMobile">
            <div className="chatsInitedHeaderMobile">
              {profileImage ? (
                <div
                  className="avatarHeaderChat"
                  style={{ backgroundImage: `url(${profileImage})` }}
                />
              ) : nameToWelcome ? (
                <div className="avatarHeaderChat">
                  {nameToWelcome.substring(0, 1)}
                </div>
              ) : (
                <></>
              )}
              <div className="nameToWelcome">Bem vindo(a), {nameToWelcome}</div>
            </div>
            <div className="chatsInitedBoxMobile">
              {loading && primeiro == 1 ? (
                <Loading color="primary" />
              ) : (
                listChats.map((chat) => {
                  return (
                    <div className="chatBox">
                      <div
                        className="chatWrapperMobile"
                        onClick={() => {
                          getMessages(
                            chat.id,
                            chat.vacancy_name,
                            chat.vacancy_thumbnail,
                            chat.vacancy
                          );
                        }}
                      >
                        <div
                          className="chatImgMobile"
                          style={{
                            backgroundImage: `url(${
                              chat.vacancy_thumbnail == null
                                ? "https://i.imgur.com/iuSUlvZ.png"
                                : JSON.parse(chat.vacancy_thumbnail)[0].link
                            })`,
                          }}
                        />
                        <div className="chatInfosMobile">
                          <div className="optionWrapper">
                            <div className="chatNameVacancy">
                              {chat.vacancy_name}
                            </div>
                            <div className="optionsMobile">{menu(chat.id)}</div>
                          </div>
                          <div className="lastMessageWrapper">
                            <span className="chatLastMessage">
                              <span className="sendName">
                                {chat.lastMessage != undefined
                                  ? chat.send_name + ": "
                                  : ""}
                              </span>
                              {chat.lastMessage != undefined
                                ? chat.lastMessage.last_message
                                : ""}
                            </span>
                            <div className="lastMessageHour">
                              {moment(chat.created_at)
                                .add(-3, "hours")
                                .format("HH:mm")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )
      ) : (
        <div className="globalChatsWrapper">
          <div className="chatsWrapper">
            <div className="chatsInitedWrapper">
              <div className="chatsInitedHeader">
                {profileImage ? (
                  <div
                    className="avatarHeaderChat"
                    style={{ backgroundImage: `url(${profileImage})` }}
                  />
                ) : nameToWelcome ? (
                  <div className="avatarHeaderChat">
                    {nameToWelcome.substring(0, 1)}
                  </div>
                ) : (
                  <></>
                )}
                <div className="nameToWelcome">
                  Bem vindo(a), {nameToWelcome}
                </div>
              </div>
              {/* <div className="chatsInitedSearch">
                <input className="chatsSearch" placeholder="Buscar..." />
                <div className="chatsSearchButton">
                  <FontAwesomeIcon icon={faSearch} color="#444" />
                </div>
              </div> */}
              {loading && primeiro == 1 ? (
                <Loading color="primary" />
              ) : listChats.length > 0 ? (
                listChats.map((chat) => {
                  return (
                    <div key={chat.id} className="chatBox">
                      <div
                        className="chatWrapper"
                        onClick={() => {
                          getMessages(
                            chat.id,
                            chat.vacancy_name,
                            chat.vacancy_thumbnail,
                            chat.vacancy
                          );
                        }}
                      >
                        <img
                          className="chatImg"
                          src={
                            chat.vacancy_thumbnail == null
                              ? "https://i.imgur.com/iuSUlvZ.png"
                              : JSON.parse(chat.vacancy_thumbnail)[0].link
                          }
                        />
                        <div className="chatInfos">
                          <div className="optionWrapper">
                            <div className="chatNameVacancy">
                              {chat.vacancy_name}
                            </div>
                            <div>{menu(chat.id)}</div>
                          </div>
                          <div className="lastMessageWrapper">
                            <span className="chatLastMessage">
                              <span className="sendName">
                                {chat.lastMessage != undefined
                                  ? chat.send_name + ": "
                                  : ""}
                              </span>
                              {chat.lastMessage != undefined
                                ? chat.lastMessage.last_message
                                : ""}
                            </span>
                            <div className="lastMessageHour">
                              {moment(chat.lastMessage.created_at)
                                .add(-3, "hours")
                                .format("HH:mm")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noChatsInited">
                  <FontAwesomeIcon icon={faInbox} size="3x" />
                  <strong>Ooops, você ainda não tem nenhuma menssagem</strong>
                  <p>
                    Quando encontrar alguém para negociar, não se esqueça de
                    seguir nossas dicas de segurança:
                  </p>
                  <div className="firstTip">
                    <FontAwesomeIcon icon={faCheck} color="#C1DEAE" />
                    <p>
                      Prefira conversar pelo chat do aplicativo, para se manter
                      mais seguro(a).
                    </p>
                  </div>
                  <div className="lastTip">
                    <FontAwesomeIcon icon={faCheck} color="#C1DEAE" />
                    <p>
                      Quando decidir pelo aluguel, converse com o anfitrião da
                      vaga sobre todas as dúvidas, verifique o endereço pelo
                      mapa e visite o local antes do pagamento.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="messagesWrapper">
              {chatSelecionado ? chatSelecionado : <NoChat />}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
