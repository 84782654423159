
import axios from 'axios';

const api = axios.create({
    baseURL: 'https://servicodados.ibge.gov.br/api'
});

const consultasIbge = {
    buscarMunicipiosPorUF:(uf)=>{
		return api.get('/v1/localidades/estados/'+uf+'/municipios');
	},
    
}
export default consultasIbge;