import React, {
  useState,
  useEffect,
  useMemo,
  Fragment,
  useCallback,
} from "react";

import { isMobile } from "react-device-detect";

import chatService from "../../services/ChatService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Picker from "emoji-picker-react";
import {
  faHome,
  faEllipsisV,
  faPaperPlane,
  faLaughWink,
} from "@fortawesome/free-solid-svg-icons";
import {
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  Spinner,
  Popover,
} from "react-bootstrap";

import Swal from "sweetalert2";
import { errorToast, successToast } from "../../components/Toast";
import Loading from "../../components/Loading";
import moment from "moment";

import "./index.css";

export default function ChatSelecionado({
  idChat,
  name,
  thumbnail,
  idVacancy,
  history,
  userId,
}) {
  const [msg, setMsg] = useState("");
  const [itemList, setItemList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingVacancy, setLoadingVacancy] = useState(false);

  const [chosenEmoji, setChosenEmoji] = useState(null);

  const [openOptions, setOpenOptions] = useState(false);
  const [mensageToCopy, setMensageToCopy] = useState("");

  const groupNames = {
    smileys_people: "Rostos",
    animals_nature: "Animais e Natureza",
    food_drink: "Comidas e Bebidas",
    travel_places: "Lugares",
    activities: "Atividade",
    objects: "Objetos",
    symbols: "Símbolos",
    flags: "Bandeiras",
    recently_used: "Recentes",
  };

  function enviaMenssagem(idChat, message) {
    setLoading(true);
    chatService.storeMenssage(idChat, message.trim()).then((res) => {
      setRefresh(!refresh);
      setMsg("");
      setLoading(false);
    });
  }

  function buscaChats() {
    chatService.entraChatSelecionado(idChat).then((res) => {
      setItemList(res.data.messages);
    });
  }

  useEffect(() => {
    buscaChats();
    var myinterval = setInterval(() => {
      buscaChats();
    }, 10000);
    return () => {
      clearInterval(myinterval);
    };
  }, [refresh, idChat]);

  function VerifyNoMsg() {
    const regexp = /(([a-z | A-Z | 0-9])\w+)/gm;
    if (msg.match(regexp)) {
      return false;
    } else if (loading) {
      return true;
    } else {
      return true;
    }
  }

  function openVacancy() {
    window.open(`/vacancy/${idVacancy}`);
  }

  function menu() {
    return (
      <>
        <div className="menuRegisto2">
          <OverlayTrigger
            key="buttonMenuRegisto"
            placement="top"
            overlay={<Tooltip id={`tooltip-editar`}>Opções</Tooltip>}
          >
            <DropdownButton
              id="DropRegistro"
              size="sm"
              title={
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  style={{ fontSize: 18 }}
                  color={"#557CF2"}
                />
              }
            >
              <Dropdown.Item
                className="opcaoMenu"
                onClick={() => {
                  openVacancy();
                }}
              >
                <div className="opcaoExcluir">
                  {loadingVacancy ? (
                    <Spinner variant="primary" />
                  ) : (
                    <FontAwesomeIcon icon={faHome} />
                  )}
                  <span className="tituloExcluir">Ver vaga</span>
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </OverlayTrigger>
        </div>
      </>
    );
  }

  const changeSubmitKey = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        enviaMenssagem(idChat, msg.trim());
      }
    },
    [enviaMenssagem]
  );

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    setMsg(msg + emojiObject.emoji);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="emoji_body">
        <Picker groupNames={groupNames} onEmojiClick={onEmojiClick} />
      </Popover.Body>
    </Popover>
  );

  const msgs = useMemo(() => {
    if (itemList) {
      let date = null;
      let separator = null;
      let isLast = true;

      return itemList.map((item) => {
        let msg_date = moment(item.createdAt).format("DD/MM/YYYY");
        if (!date || date !== msg_date) {
          separator = <div className="separador">{date}</div>;
          date = msg_date;
        } else {
          separator = "";
          isLast = false;
        }
        return parseInt(userId) === item.created_by ? (
          <Fragment key={item.id}>
            {!isLast && separator}
            <div key={item.id} className="messageSendBallon">
              <div>{item.message}</div>
              <span className="messageSendHour">
                {moment(item.createdAt).add(-3, "hours").format("HH:mm")}
              </span>
            </div>
          </Fragment>
        ) : (
          <Fragment key={item.id}>
            {!isLast && separator}
            <div key={item.id} className="messageReceivedBallon">
              <div>{item.message}</div>
              <span className="messageReceivedHour">
                {moment(item.createdAt).add(-3, "hours").format("HH:mm")}
              </span>
            </div>
          </Fragment>
        );
      });
    }
  }, [itemList]);

  return (
    <>
      {isMobile ? (
        <div className="selectedMessageWrapperMobile">
          <div className="selectedMessageHeaderMobile">
            <div style={{ display: "flex", alignItems: "center" }}>
              {thumbnail ? (
                <div
                  className="avatarHeaderChatMobile"
                  style={{
                    backgroundImage: `url(${
                      thumbnail == null
                        ? "https://i.imgur.com/iuSUlvZ.png"
                        : JSON.parse(thumbnail)[0].link
                    })`,
                  }}
                />
              ) : name ? (
                <div className="avatarHeaderChatMobile">
                  {name.substring(0, 1)}
                </div>
              ) : (
                <></>
              )}
              <div className="nameToWelcome">{name}</div>
            </div>
            {menu()}
          </div>
          <div className="selectedMessageMobile">{msgs}</div>
          <div className="selectedMessageInputWrapper">
            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
              <div className="newspaper">
                <FontAwesomeIcon icon={faLaughWink} color="#3b5998" />
              </div>
            </OverlayTrigger>
            <textarea
              className="selectedMessageInput"
              placeholder="Digite um mensagem..."
              onChange={(e) => {
                setMsg(e.target.value);
              }}
              value={msg}
              onKeyPress={(e) => {
                changeSubmitKey(e);
              }}
            />
            <button
              className="buttonSendMsg"
              style={{
                backgroundColor:
                  VerifyNoMsg() || loading
                    ? "rgba(59, 89, 152, 0.3)"
                    : "#3b5998",
              }}
              onClick={() => enviaMenssagem(idChat, msg.trim())}
              disabled={VerifyNoMsg() || loading}
            >
              {loading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                <FontAwesomeIcon icon={faPaperPlane} size={"2x"} color="#fff" />
              )}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="selectedMessageHeader">
            <div style={{ display: "flex", alignItems: "center" }}>
              {thumbnail ? (
                <div
                  className="avatarHeaderChat"
                  style={{
                    backgroundImage: `url(${
                      thumbnail == null
                        ? "https://i.imgur.com/iuSUlvZ.png"
                        : JSON.parse(thumbnail)[0].link
                    })`,
                  }}
                />
              ) : name ? (
                <div className="avatarHeaderChat">{name.substring(0, 1)}</div>
              ) : (
                <></>
              )}
              <div className="nameToWelcome">{name}</div>
            </div>
            {menu()}
          </div>
          <div className="messageBoxWrapper">{msgs}</div>
          <div className="selectedMessageInputWrapper">
            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
              <div className="newspaper">
                <FontAwesomeIcon icon={faLaughWink} color="#3b5998" />
              </div>
            </OverlayTrigger>
            <textarea
              className="selectedMessageInput"
              placeholder="Digite um mensagem..."
              onChange={(e) => {
                setMsg(e.target.value);
              }}
              value={msg}
              onKeyPress={(e) => {
                changeSubmitKey(e);
              }}
            />
            <button
              className="buttonSendMsg"
              style={{
                backgroundColor:
                  VerifyNoMsg() || loading
                    ? "rgba(59, 89, 152, 0.3)"
                    : "#3b5998",
              }}
              onClick={() => enviaMenssagem(idChat, msg.trim())}
              disabled={VerifyNoMsg() || loading}
            >
              {loading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                <FontAwesomeIcon icon={faPaperPlane} size={"2x"} color="#fff" />
              )}
            </button>
          </div>
        </>
      )}
    </>
  );
}
