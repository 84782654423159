import React, { useEffect, useState } from "react";
import "./index.css";
import { useParams } from "react-router-dom";

import YouTube from "react-youtube";

import { Spinner } from "react-bootstrap";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import chatService from "../../services/ChatService";

import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { RiMapPinLine } from "react-icons/ri";
import { IoShareOutline } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faVenusMars,
  faEdit,
  faFlag,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { errorToast, successToast } from "../../components/Toast";

import SimpleImageSlider from "react-simple-image-slider";
import OfferMidias from "./OfferMidias";
import Chat from "../../components/Chat";
import ModalCafofo from "../../components/Modal";
import ReportVacancy from "./ReportVacancy";
import New from "../../pages/New";
import Share from "./Share";

import {
  formatarParaMascaraMoedaComSeparadorDeMilhares,
  celularMask,
} from "../../components/TextMask";

import api from "../../services/api";

export default function Detail({ history }) {
  let { id } = useParams();
  const [item, setItem] = useState({});
  const [favAsync, setFavAsync] = useState([]);
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const [openModalReport, setOpenModalReport] = useState(false);
  const [openModalShare, setOpenModalShare] = useState(false);
  const [vacancyId, setVacancyId] = useState(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingVacancy, setLoadingVacancy] = useState(false);
  const [midia, setMidia] = useState("Foto");

  useEffect(() => {
    try {
      setLoadingVacancy(true);
      chatService.openVacancySelected(id).then((res) => {
        if (res.status == 204) {
          setLoadingVacancy(false);
          errorToast.fire({
            title: `Vaga excluída pelo usuário`,
          });
          history.push("/dashboard");
        } else {
          setLoadingVacancy(false);
          setItem(res.data);
        }
      });
    } catch {
      setLoadingVacancy(false);
      errorToast.fire({
        title: `Erro ao entrar na vaga, tente novamente`,
      });
    }
  }, []);

  function loadFavorites() {
    if (localStorage.getItem("favorites")) {
      setFavAsync(localStorage.getItem("favorites"));
    }
  }

  useEffect(() => {
    async function load() {
      const user_id = localStorage.getItem("user");
      if (user_id) {
        setToken(user_id);
      }
      const id = localStorage.getItem("id");
      if (id) {
        setUser(Number(id));
      }
    }
    load();
  }, []);

  useEffect(() => {
    loadFavorites();
  }, []);

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match.slice(-1)[0];
  }

  function ImageSliderData(thumb) {
    let arr = [];
    JSON.parse(thumb).forEach((item) => {
      arr.push({ url: item.link });
    });

    return arr;
  }

  function handleToggleFavorites(id) {
    const favorites = localStorage.getItem("favorites");
    let favAsyncArr = [];
    if (favorites) {
      favAsyncArr = JSON.parse(favorites);
    }
    if (favAsyncArr.includes(id)) {
      const favoritesIndex = favAsyncArr.findIndex((vacancyItem) => {
        return vacancyItem === id;
      });
      favAsyncArr.splice(favoritesIndex, 1);
      setFavAsync(favAsyncArr);
    } else {
      favAsyncArr.push(id);
    }
    localStorage.setItem("favorites", JSON.stringify(favAsyncArr));
    setFavAsync(favAsyncArr);
  }

  function handleReportPress(vacancyId) {
    if (!user) {
      errorToast.fire({
        title: "Faça login para usar a funcionalidade",
      });
    } else {
      setOpenModalReport(true);
      setVacancyId(vacancyId);
    }
  }

  async function handleDelete(vacancy) {
    setLoading(true);
    Swal.fire({
      title: "Atenção!",
      text: "Você tem certeza que deseja excluir a vaga?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (user === item.user) {
          try {
            await api.delete("/vacancies/" + vacancy, {
              headers: {
                Authorization: token,
              },
            });
            setLoading(false);
            successToast.fire({
              title: `Vaga excluida com sucesso`,
            });
            history.push("/user_vacancies");
          } catch {
            errorToast.fire({
              title: `Erro ao excluir, tente novamente`,
            });
            setLoading(false);
          }
        }
      }
    });
  }
  return loadingVacancy ? (
    <Loading color="primary" />
  ) : (
    <>
      <div className="ContainerDetail">
        <div className="infoWrapper">
          <div className="vacancyNameDetail">{item.name}</div>
          <div className="vacancyLocationWrapper">
            <div className="vacancyLocationDetail">
              <RiMapPinLine color="#222222" />
              {item.city} - {item.state}
            </div>
            <div className="options-wrapper">
              <button
                className="btnFavorite"
                onClick={() => {
                  setOpenModalShare(true);
                }}
              >
                <IoShareOutline color="#222222" className="saveIcon" />
                <span>Compartilhar</span>
              </button>
              {user === item.user ? (
                <button
                  onClick={() => {
                    handleDelete(item.id);
                  }}
                  className="btnFavorite"
                >
                  {loading ? (
                    <Spinner
                      style={{ marginRight: 10 }}
                      animation="border"
                      variant="primary"
                      size="sm"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTrash}
                      color="#FC4F4F"
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <span className="buttonText">Excluir</span>
                </button>
              ) : (
                <button
                  className="btnFavorite"
                  onClick={() => {
                    handleToggleFavorites(parseInt(item.id));
                  }}
                >
                  {favAsync.includes(parseInt(item.id)) ? (
                    <AiFillHeart color="#FF7272" className="saveIcon" />
                  ) : (
                    <AiOutlineHeart color="#222222" className="saveIcon" />
                  )}
                  <span>Salvar</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="imgWrapperDetail">
          {item.thumbnail_url === null ||
          item.thumbnail_url === "" ||
          item.thumbnail_url === undefined ||
          item.thumbnail_url === "[]" ||
          item.thumbnail_url.length <= 0 ? (
            <img
              className="imgBoxDetail"
              src={"https://i.imgur.com/iuSUlvZ.png"}
            />
          ) : midia === "Foto" ? (
            <SimpleImageSlider
              width={800}
              height={500}
              images={ImageSliderData(item.thumbnail_url)}
              showBullets={true}
              showNavs={true}
              navSize={30}
              navMargin={10}
              style={{ borderRadius: 11 }}
            />
          ) : (
            <YouTube
              videoId={youtube_parser(item.videoURL)}
              id={item.id.toString()}
              className="youtube-wrapper"
            />
          )}
          {item.videoURL ? (
            <div className="photo-video-selector">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setMidia("Foto");
                }}
                style={midia === "Foto" ? { border: "1px solid #fff" } : {}}
              >
                Foto
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setMidia("Video");
                }}
                style={midia === "Video" ? { border: "1px solid #fff" } : {}}
              >
                Video
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="bottomWrapper">
          <div className="leftColumn">
            <div className="descriptionWrapper">
              <span>Descrição:</span>
              <div className="description">{item.description}</div>
            </div>
            <div className="benefitsWrapper">
              <span>O que temos a oferecer:</span>
              <div className="benefits">
                <OfferMidias benefits={item.benefits} />
              </div>
            </div>
            <div
              className="reportBox-mobile"
              onClick={() => {
                handleReportPress(item.id);
              }}
            >
              <FontAwesomeIcon
                icon={faFlag}
                color="#444"
                style={{ marginRight: 10 }}
              />
              <span>Reportar a vaga</span>
            </div>
            <div className="wppWrapper">
              <div className="boxGenWpp">
                <span>WhatsApp:</span>
                {parseInt(item.show_whatsapp) === 1 ? (
                  <div className="wpp">{celularMask(item.whatsapp)}</div>
                ) : (
                  <div className="wpp">Não exibido</div>
                )}
              </div>
              {item.gender === "" || item.gender === "Selecione" ? (
                <></>
              ) : (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="textBenefits">Vaga {item.gender}</div>
                  {item.gender === "Masculina" ? (
                    <FontAwesomeIcon icon={faMars} color="#87CEEB" />
                  ) : item.gender === "Feminina" ? (
                    <FontAwesomeIcon icon={faVenus} color="#FF99CC" />
                  ) : item.gender === "Unisex" ? (
                    <FontAwesomeIcon icon={faVenusMars} color="#999900" />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="infosWrapper">
            <div className="infoBox">
              <div className="infoChatBox">
                <div className="priceBox">
                  <div style={{ width: "50%" }}>
                    {formatarParaMascaraMoedaComSeparadorDeMilhares(
                      item.price
                    ) === "PREÇO A NEGOCIAR" ? (
                      <span style={{ fontSize: 22 }}>PREÇO A NEGOCIAR</span>
                    ) : (
                      <>
                        <span className="priceText">
                          R$
                          {formatarParaMascaraMoedaComSeparadorDeMilhares(
                            item.price
                          )}{" "}
                        </span>
                        /mês
                      </>
                    )}
                  </div>
                  {user === item.user ? (
                    <button
                      onClick={() => {
                        setOpenModalEdit(true);
                      }}
                      className="buttonMyVacancy"
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        color="#fff"
                        style={{ marginRight: 10 }}
                      />
                      <span className="buttonText">Editar</span>
                    </button>
                  ) : (
                    <Chat item={item} history={history} />
                  )}
                </div>
                <div
                  className="reportBox"
                  onClick={() => {
                    handleReportPress(item.id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFlag}
                    color="#444"
                    style={{ marginRight: 10 }}
                  />
                  <span>Reportar a vaga</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileFooter">
            <div>
              <span className="priceText">
                R$
                {formatarParaMascaraMoedaComSeparadorDeMilhares(
                  item.price
                )} /{" "}
              </span>
              <span>mês</span>
            </div>
            {user === item.user ? (
              <button
                onClick={() => {
                  setOpenModalEdit(true);
                }}
                className="buttonInitChat"
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  color="#fff"
                  style={{ marginRight: 10 }}
                />
                <span className="buttonText">Editar</span>
              </button>
            ) : (
              <Chat item={item} history={history} />
            )}
          </div>
        </div>
      </div>
      <ModalCafofo
        className="modalFiltros"
        open={openModalReport}
        close={() => {
          setOpenModalReport(false);
        }}
        titulo={"Reportar vaga"}
        size="md"
      >
        <ReportVacancy
          vacancyId={vacancyId}
          token={token}
          user={user}
          close={() => {
            setOpenModalReport(false);
          }}
        />
      </ModalCafofo>
      <ModalCafofo
        className="modalFiltros"
        open={openModalEdit}
        close={() => {
          setOpenModalEdit(false);
        }}
        titulo={"Editar vaga"}
        size="lg"
      >
        <New
          _price={item.price}
          _benefits={item.benefits}
          _name={item.name}
          _description={item.description}
          _city={item.city}
          _state={item.state}
          _republic={item.republic}
          thumbnailURL={item.thumbnail_url}
          _whatsapp={item.whatsapp}
          _videoURL={item.videoURL}
          _gender={item.gender}
          _showWpp={item.show_whatsapp}
          editing={true}
          idVacancy={item.id}
          history={history}
        />
      </ModalCafofo>
      <ModalCafofo
        className="modalFiltros"
        open={openModalShare}
        close={() => {
          setOpenModalShare(false);
        }}
        titulo={"Compartilhar a vaga"}
        size="md"
      >
        <Share item={item} />
      </ModalCafofo>
    </>
  );
}
