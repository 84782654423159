import React from "react";
import "./App.css";
import logo from "./assets/logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";

import Routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="content">
      <Routes />
    </div>
  );
}

export default App;
