import axios from "axios";

const api = axios.create({
  baseURL: "https://api.imgur.com/3",
});

const imgbb = axios.create({
  baseURL: "https://api.imgbb.com/1",
});

const cafofoApi = axios.create({
  baseURL: "https://api.meucafofo.com/",
});

const consultasIbge = {
  uploadImage: (img) => {
    return api.post("/upload", img, {
      headers: {
        Authorization: "Client-ID b9b0dbf4bea04b1",
        "Content-Type": "multipart/form Data",
      },
    });
  },
  uploadImageXMLHttp: (data) => {
    const xhr = new XMLHttpRequest();

    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = (e) => {
        if (xhr.readyState !== 4) {
          return;
        }

        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject("Request Failed");
        }
      };
      xhr.open("POST", "https://api.imgur.com/3/upload");
      xhr.setRequestHeader("Authorization", "Client-ID b9b0dbf4bea04b1");
      xhr.send(data);
    });
  },
  uploadImgbb: (image) => {
    return imgbb.post("/upload", image, {
      params: {
        key: "8a3f17b7ca151b4a0af255d6f1e5155c",
      },
    });
  },
  uploadApiCafofo: (image) => {
    return cafofoApi.post(
      "/vacancies/image",
      { image },
      {
        headers: {
          Authorization: localStorage.getItem("user"),
        },
      }
    );
  },
};
export default consultasIbge;
