import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import Base from "./base.js";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import New from "./pages/New";
import Detail from "./pages/Detail";
import Chats from "./pages/Chats";
import UserVacancies from "./pages/UserVacancies";
import PrivacyPolice from "./pages/PrivacyPolice";

export default function Routes({}) {
  const [tokenValido, setTokenValido] = useState(true);
  const history = useHistory();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        {tokenValido && (
          <Base
            history={history}
            conteudo={
              <div style={{ height: "100%" }}>
                <>
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/new" component={New} />
                  <Route path="/vacancy/:id" component={Detail} />
                  <Route path="/chats" component={Chats} />
                  <Route path="/user_vacancies" component={UserVacancies} />
                  <Route
                    path="/politica-de-privacidade"
                    component={PrivacyPolice}
                  />
                </>
              </div>
            }
          ></Base>
        )}
      </Switch>
    </BrowserRouter>
  );
}
